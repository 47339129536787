
// ######################################## //
// ## BODY.SCSS ## //
// ######################################## //

html, body {}

// Body
body {
  @include ff-primary;
  color: $theme-primary-text;
  letter-spacing: 0.03rem;
  -webkit-overflow-scrolling: touch;
  background: $body-bg;
  
  // Overflow (Hidden)
  &.overflow-hidden {
    overflow: hidden;
  }
}

// Select Style For Webkit
select {
  
  // Form Control
  &.form-control {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 24 24'><path fill='#999' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
    
    // On mobile
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }
  }
}


// Height Primary
.height-primary {
  min-height: calc(100vh - 109px);
  
  // On mobile
  @include mobile {
    height: auto;
  }
}

// main-section
.main-section {
  position: relative;
  
  &:before {
    // background: url('./assets/img/bg/circle2.svg');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 50%;
    background-position: center;
    background-attachment: fixed;
    opacity: 0.04;
    content: '';
  }
}

.bg-illustration {
  position: relative;
  
  &::before {
    background-image: url('./assets/images/bg/5_student.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background-position: left;
    background-size: 60%;
    content: '';
    background-repeat: no-repeat;
    
    @include mobile {
      background-size: cover;
      background-position: 40% center;
    }
  }
}

// overflow-hidden
.overflow-hidden {
  overflow: hidden;
  
  @include mobile {
    overflow: auto;
  }
}

// main layout section
.layout {
  background: $body-bg;
}

// Large Screen 100%
.lg-col-100 {
  
  @include desktop-lg {
    width: 80%;
  }
}

// Gradient Color
.bggradient1 {
  background:linear-gradient(135deg, #17ead9 0%,#6078ea 100%);
}
.bggradient2 {
  background: linear-gradient(135deg, #fad961 0%,#f76b1c 100%);
}
.bggradient3 {
  background:linear-gradient(135deg, #42e695 0%,#3bb2b8 100%);
}


// Main content wrapper
.main-content-wrapper {
  margin-left: 200px;
}

.content-wrapper {
  min-height:calc(100vh - 109px);
}

.padding-md {
  padding: 20px;
}

// Ant Card 
.ant-card {
  border: none;
  border-radius: $radius-primary;
  overflow: hidden;
}

.add-modal-container {
  width: 420px !important;
}

.action-buttons-container {

  button {
    margin : 10px 10px 0 10px;
  }
}

.profile-details-container{
  padding: 20px;

.profile-card {
  
  .ant-card-body {
    padding-top: 10px;
  }

  .attribute-row {
    padding: 5px 0;
  }

  .attribute-name {
    text-transform: capitalize;
  }
}
}

.families-card-container {
  
  padding: 20px;

  .families-card {
    
    .ant-card-body {
      padding-top: 10px;
    }
    .ant-list-item {
      padding: 5px;
    }
    
    .ant-btn-link {
      padding: 0;
    }

  }
}



.clickable {
  cursor: pointer;
}