// ######################################## //
// ## PANEL.SCSS ## //
// ######################################## //

.quiz-container-style {
    padding: 1.5rem;

    
    .select-section {
        margin-top: 25px;
    }
    
    .bg-question-section {
        background: lighten($theme-secondary, 25%);
        display: flex;
        align-items: center;
        border-radius:4px;
        overflow: hidden;
        
        .title-xs {
            margin: 0;
            margin-left: 10px;
        }
    }
    
    .number-style {
        background: lighten($theme-secondary, 5%);
        color:lighten($theme-secondary, 100%);
        // color:"white";
        padding: 4px 20px;
        font-size: 20px;
    }
    
    .checkbox-group-style {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        margin-left: 20px;
        
        .ant-checkbox-wrapper, .ant-radio-wrapper {
            margin: 10px 0;
        }
    }
    
    .question-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }



    .time-sec {
        display: flex;
        align-items: center;
        
        .time {
            margin-left: 5px;
            color: $theme-primary;
        }
    }
    
    .footer-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        
        .right-section {
            
            .ant-btn {
                margin-left: 10px;
            }
        }
    }
    
    .ant-btn {
        height: 31px;
        font-size: 11px;
        border-radius: 50px;
    }
    
    .correct-answer {
        color: $theme-primary;
        
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $theme-primary;
            border-color: $theme-primary;
        }

        .ant-radio-inner::after {
            background-color: $theme-primary;
        }
        
        .ant-radio-checked .ant-radio-inner {
            border-color: $theme-primary;
        }
    }
    
    .wrong-answer {
        color: $theme-danger;
        
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $theme-danger;
            border-color: $theme-danger;
        }
        .ant-radio-inner::after {
            background-color: $theme-danger;
        }
        
        .ant-radio-checked .ant-radio-inner {
            border-color: $theme-danger;
        }
    }
}

// flipmodalCard
.flipmodalCard {

    .ant-modal-body {
        padding: 10;
    }

    .ant-modal-content {
        background: none;
        box-shadow: none;
    }
}

.notes-details-container {
    padding:25;
    background-color:red($color: #FF0000);
  }


.flippy-cardContainer {
    min-height: auto !important;
    height: auto !important;
    width: 450px !important;

    .title {
        color: $white;
    }

    .info {
        color: $white;
    }

    .btn-primary {
        background-color: darken($theme-primary, 7%);
    }
}

.frontstyle {
    background: $theme-primary !important;
}

.backstyle {
    background: $theme-secondary !important;
    
}


.backstyle, .frontstyle {
    flex-direction: column !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
}