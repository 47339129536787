
// ######################################## //
// ## HEADER.SCSS ## //
// ######################################## //\

// Sidebar Panel
.sidebar-panel {
    overflow: hidden;
    overflow-y: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    background: $white;
    z-index: 3;
}


// Sidebar Menu section
.sidebar-section {
    z-index: 2;
  
    .logo {
      padding: 1.5rem 0;
    }
  
    .ant-menu-light {
      border: none;
    }
    
    .ant-menu-item {
      
      &:after {
        left: 0;
        border-left: 3px solid $theme-primary;
        border-right: none;
      }
      
      &:hover, &:focus {
        background-color: rgba($theme-primary, 0.2) !important;
        color: darken($theme-primary, 5%)
      }
    }
    
    .ant-menu-item-selected {
      background-color: rgba($theme-primary, 0.2) !important;
      color: darken($theme-primary, 5%)
    }
    
  }