// ######################################## //
// ## PANEL.SCSS ## //
// ######################################## //

.dashboard-panel {
  padding: 1.5rem;
  z-index: 1;
  
  .primary-title {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  
  .head-info {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
     
    a {
      color: $white;
    }
    .icon-sec {
      width: 35px;
    }
  }
  
  .icon-backdrop {
    opacity: 0.1;
    position: absolute;
    top: 0;
    right: 17px;
    display: flex;
    align-items: center;
    height: 100%;
    transform: rotate(20deg);
  }
  
  .bg-light-blue {
    background: $theme-secondary;
    color: $white;
  }

  .bg-light-orange {
    background: $theme-light-orange;
    color: $white;
  }
  
  .share-detail-sec {
    display: block;
    width:100%;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    overflow-wrap: break-word;
    margin-top: 1rem;
    
    .ant-avatar {
      background: transparent;
      border: 1px solid $white;
      width: 20px;
      height: 20px;
      font-size: 0.75rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .card-icon {
    overflow: hidden;
    border-radius: 0.4rem;
    margin-bottom: 1.5rem;

    .ant-card-body {
      padding: 10px 20px;
    }
  }

  // card-tag
  .card-tag {
    margin:2px;
    border: none;
    float:left;
    font-size: 0.75rem;
    font-weight: 300;
    border-radius: 25px;
    padding: 0 1rem;
    color: $gray-2;
    
  }

  // Question sec list
  .icon-success {
    background: $theme-primary;
  }

  // questions section
  .question-sec {
    position: relative;
    
    .ant-list-item-meta-title {
      font-size: 14px;
      line-height: 22px;
    }

    // ant-spin-container
    .questions-item {
      position: relative;
      z-index: 2;

      .ant-list-item {
        margin-bottom: 30px;
      }
    }
  }

   // img-graphic
   .img-graphic {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.3;
    z-index: 0;

    .img-graphic-item {
      width: 200px;
    }
  }
  
  .back-button-container {
    a {
      color : $gray-7;
    }
  }
  
  .collection-title {
    font-size: 1.5rem;
    color: $gray-2;
    padding: 10px 0;

  }

  .add-button-container {
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    z-index: 10;

    .ant-btn-circle {
      width: 50px;
      height: 50px;
    }
  }
}

