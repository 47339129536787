// ######################################## //
// ## INDEX.SCSS ## //
// ######################################## //

.content-wrapper {
  
  .title-bar-section {
    border: none;
    
    // Card Body padding
    .ant-card-body {
      padding: 10px 24px;
    }
  }
}

.status-card-section {
  
  .title {
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
  }
  
  .ant-card-body {
    padding: 15px;
    display: flex;
    align-items: center;
  }
  
  .icon-sec {
    flex: auto;
  }
  
  .number-sec {
    font-size: 2rem;
    font-weight: 600;
    text-align: right;
    opacity: 0.5;
  }
  
  .icon-img {
    width: 50px;
  }
}

// quiz-tasksection
.quiz-tasksection {
  margin-top: 20px;
}

.card-header-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.4rem;
  border-bottom: 1px solid $gray-e;
  padding-bottom: 1rem;
  
  .title {
    font-size: 0.9rem;
    color: $gray-4;
  }
  
  .anticon {
    font-size: 0.95rem;
    margin-right: 0.5rem;
  }
  
}

// card-list-task
.card-list-task {
  
  .ant-list-item-meta-description {
    font-size: 11px;
    text-transform: uppercase;
    line-height: 1.8;
  }
  
  .ant-list-item-meta-title {
    font-size: 14px;
    font-weight: bold;
    color: $gray-4;
  }
  
  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }
  
  .ant-tag {
    background-color: $theme-secondary;
    color: $white;
  }
}

.circle-tag {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.list-content-style {
  
  .list-title {
    font-weight: 500;
    opacity: 0.5;
    margin-bottom: 0;
  }
  
  .list-text-dark {
    font-weight: 600;
    font-size: 0.8rem;
  }
  
  .list-sec {
    margin-right: 3rem;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  .ant-list-item-meta-description {
    max-width: 90%;
  }
  
  .circle-tag {
    font-size: 13px;
  }
}

.list-relative-sec {
  
  .ant-list-item {
    position: relative;
  }
  
  .ant-list-item {
    align-items: flex-start;
  }
}

.list-date-sec {
  font-size: 12px;
  opacity: 0.7;
}

.flex-column-style {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  
  .list-date-sec {
    margin-bottom: 0.8rem;
  }
}

.table-primary {
  
  .ant-table-body {
    margin: 0 0px;
  }
  
  .ant-table-small {
    border: none;
    border-radius: 0;
  }
}


// Calendar section style

.calendar-section {
  
  .ant-fullcalendar-header {
    padding: 11px 16px 11px 0;
    text-align: right;
    display: flex;
    
    .ant-radio-button-wrapper:first-child {
      border-radius: 4px;
    }
    
    .ant-radio-button-wrapper:last-child {
      display: none;
    }
    
    .ant-table-pagination.ant-pagination {
      margin: 16px 0 0;
    }
  }
}
