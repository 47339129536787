
// ######################################## //
// ## TEXTS.SCSS ## //
// ######################################## //


// Mark (Highlight) Tag
mark {
  background-color: lighten($theme-primary-text, 78%);
  font-weight: 600;
  
  // Mark Blue Dark
  &.mark-dark-blue {
    background-color: darken($theme-primary-bg, 8%);
  }
}

// Text Primary
.text-primary {
  color: $theme-primary !important;
}

// Text Primary
.text-primary-dark {
  color: $theme-primary-text !important;
}

// Text Secondary
.text-secondary {
  color: rgba($theme-primary-text, 0.71) !important;
}

// Text Info
.text-info {
  color: $theme-info !important;
}

// Text Success
.text-success {
  color: $theme-success !important;
}

// Text Danger
.text-danger {
  color: $theme-danger !important;
}

// Text Cyan
.text-cyan {
  color: darken($theme-cyan, 8%) !important;
}

// Text Yellow
.text-yellow {
  color: darken($theme-yellow, 10%) !important;
}

.title {
  color: $gray-7;
  
  // title small
  &.title-sm {
    font-size: 1rem;
  }
  
  // title medium
  &.title-md {
    font-size: 1.2rem;
  }
  
  // title large
  &.title-lg {
    font-size: 1.6rem;
  }
}

// small xs txt
small {
  
  // xs txt
  .xs-small {
    font-size: 0.68rem;
  }
}

// lighter
.text-light {
  opacity: 0.5;
}

// dark text
.text-dark {
  color: $gray-7;
}

// link color
.link-gray {
  color: $gray-7;
  opacity: 0.7;
}

// primary link
.link-primary {
  color: $theme-primary;

  &:hover {
    color: lighten($theme-primary, 10%);
  }
}

// text formating
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}